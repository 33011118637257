<template>
  <div class="l-wrapper">
    <div class="q-pa-md">
      <q-btn
        unelevated
        class="full-width q-mb-sm"
        color="grey-8"
        label="Filter"
        @click="
          tempFilter = JSON.parse(JSON.stringify(options));
          showFilter = !showFilter;
        "
      ></q-btn>
      <q-input
        outlined
        label="Cari Nama Perusahaan"
        v-model="options.searchTerm"
        @update:model-value="
          pagination.currentPage = 1;
          filteringVisit();
        "
      ></q-input>
    </div>
    <q-scroll-area class="l-grow">
      <q-list separator>
        <q-item
          class="bg-grey-4"
          clickable
          v-for="(val, i) in filteredVisit"
          :key="i"
          v-ripple
          :to="'/visit/sales-summary/' + val.id"
        >
          <q-item-section>
            <q-item-label
              v-if="val.type.toLowerCase().indexOf('no-visit') != -1"
              >{{ val.type }}</q-item-label
            >
            <q-item-label v-else
              >{{ val.nama_customer }} ({{ val.type }})</q-item-label
            >
            <q-item-label caption>{{
              moment(val.date).format("DD MMM YYYY")
            }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <q-item-label lines="1">
              <q-icon
                class="text-grey-6"
                name="accessibility_new"
                style="font-size: 1.6em;"
              />
              :
              {{ val.nama_sales }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <div class="q-pa-lg flex flex-center">
      <q-pagination
        v-model="pagination.currentPage"
        :max="pagination.totalPage"
        input
        input-class="text-orange-10"
        @update:model-value="filteringVisit()"
      />
    </div>
    <q-dialog v-model="showFilter" position="bottom">
      <q-card style="min-width:300px;">
        <q-card-section class="row justify-between items-center">
          <a class="text-h6">Filter :</a>
          <q-btn flat :ripple="false" icon="close" v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="column q-gutter-sm">
          <div class="column q-gutter-xs">
            <label class="text-bold"> Tipe Visit :</label>
            <q-select
              outlined
              :options="data.visitType"
              v-model="tempFilter.selTipe"
              multiple
            >
              <template
                v-slot:option="{ itemProps, opt, selected, toggleOption }"
              >
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt" />
                  </q-item-section>
                  <q-item-section side>
                    <q-toggle
                      :model-value="selected"
                      @update:model-value="toggleOption(opt)"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold"> Tanggal Visit :</label>
            <q-input label="Tanggal" v-model="dateModel" outlined readonly>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="tempFilter.selDate"
                      range
                      mask="YYYY-MM-DD"
                    >
                      <div class="row items-center justify-between">
                        <q-btn
                          outline
                          color="primary"
                          label="Reset"
                          @click="
                            tempFilter.selDate = {
                              from: null,
                              to: null,
                            }
                          "
                        ></q-btn>
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            label="Reset"
            color="primary"
            @click="
              options.selTipe = [];
              options.selDate = { from: null, to: null };
              pagination.currentPage = 1;
              filteringVisit();
            "
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            label="Apply"
            color="primary"
            @click="
              options.selTipe = tempFilter.selTipe;
              options.selDate = tempFilter.selDate;
              pagination.currentPage = 1;
              filteringVisit();
            "
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// TODO: pagination, loading animation

import { reactive, ref } from "vue";
import { onMounted, computed, watch } from "vue";
import useVisit from "./useVisit";
import moment from "moment";
export default {
  setup() {
    const {
      data,
      getHistorySales,
      route,
      router,
      getType,
      visitHistoryList,
      pagination,
      $q,
    } = useVisit();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getHistorySales(route.params.id);
        await getType();
        data.visitType.unshift("NO-VISIT");
        if (route.params.filter) {
          let filter = JSON.parse(route.params.filter);
          options.searchTerm = filter.searchTerm;
          options.selTipe = filter.selTipe;
          options.selDate = filter.selDate;
        }
        filteringVisit();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let dateModel = computed(() => {
      if (tempFilter.value.selDate.from && tempFilter.value.selDate.to) {
        return `${moment(tempFilter.value.selDate.from).format(
          "DD MMM YYYY"
        )} - ${moment(tempFilter.value.selDate.to).format("DD MMM YYYY")}`;
      } else if (typeof tempFilter.value.selDate == "string") {
        return `${moment(tempFilter.value.selDate).format("DD MMM YYYY")}`;
      } else {
        return "-";
      }
    });

    // filtering property
    let tempFilter = ref({});
    let options = reactive({
      searchTerm: "",
      selTipe: [],
      selDate: { from: null, to: null },
      // current:1
    });

    //router replace area
    watch([options], (newValues, prevValues) => {
      let temp = newValues[0];
      let routeFilter = JSON.stringify(temp);
      router.replace({
        path: `/visit/history/${route.params.id}/${routeFilter}`,
      });
    });

    //filtering area
    let filteredVisit = ref([]);
    let filteringVisit = async () => {
      pagination.filterType = options.selTipe;
      pagination.filterDate = options.selDate;
      pagination.search = options.searchTerm;

      await getHistorySales(route.params.id);
      let visit = JSON.parse(JSON.stringify(visitHistoryList.value));
      filteredVisit.value = visit;
      // options.current = pagination.currentPage
    };

    return {
      showFilter: ref(false),
      data,
      options,
      filteredVisit,
      filteringVisit,
      tempFilter,
      dateModel,
      pagination,
      moment,
    };
  },
};
</script>
